import { validatePhoneNumber,
  validateVerificationCode } from './utils';
import { Alert } from 'antd';
import authApi from 'api/auth';
import userApi from 'api/user';
import { actionCreator } from 'api/utils';
import { isAxiosError } from 'axios';
import { type FC,
  useEffect,
  useMemo} from 'react';
import { createContext,
  useCallback,
  useState } from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { type AppDispatch } from 'store';
import { authActionTypes } from 'store/actions';
import {
  getItemsFromStorage,
  type LoginAsFromStorage,
  type VerificationChannel} from 'types';
import { storeItemsInStorage} from 'types';

const MAX_VERIFICATION_RESEND = 15;

type ChangePhoneNumberContextType = {
  addPhoneNumber: (currentPhoneInput: string) => void,
  addVerificationNumber: (verificationNumber: string) => void,
  canInputVerificationCode: boolean,
  canSendVerificationCode: boolean,
  canSubmitPhoneNumber: boolean,
  newPhoneNumber: string,
  setSubmitVerificationErrors: React.Dispatch<React.SetStateAction<string[]>>,
  submitAddNumberRequest: (selectedMethod: string) => Promise<void>,
  submitChangeNumberRequest: () => Promise<void>,
  submitPhoneNumberErrors: string[],
  submitPhoneNumberLoading: boolean,
  submitPhoneNumberSuccess: boolean,
  submitVerificationErrors: string[],
  submitVerificationLoading: boolean,
  submitVerificationSuccess: boolean,
  verificationNumber: string,
  verificationTimer: number,
  verifyVerificationNumber: () => Promise<{ errorMessage: string, success: boolean, }>,
};

type ChangePhoneNumberContextProviderProps = {
  children?: React.ReactNode,
};

const INITIAL_STATE: ChangePhoneNumberContextType = {
  addPhoneNumber: () => {},
  addVerificationNumber: () => {},
  canInputVerificationCode: false,
  canSendVerificationCode: false,
  canSubmitPhoneNumber: false,
  newPhoneNumber: '',
  setSubmitVerificationErrors: () => {},
  submitAddNumberRequest: () => Promise.resolve(),
  submitChangeNumberRequest: () => Promise.resolve(),
  submitPhoneNumberErrors: [],
  submitPhoneNumberLoading: false,
  submitPhoneNumberSuccess: false,
  submitVerificationErrors: [],
  submitVerificationLoading: false,
  submitVerificationSuccess: false,
  verificationNumber: '',
  verificationTimer: 0,
  verifyVerificationNumber: async () => {
    return { errorMessage: '',
      success: false };
  },
};

export const ChangePhoneNumberContext = createContext<
ChangePhoneNumberContextType>(INITIAL_STATE);

export const ChangePhoneNumberContextProvider: FC<
ChangePhoneNumberContextProviderProps> = ({ children }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {loginAs} = getItemsFromStorage<LoginAsFromStorage>([
    'loginAs',
  ], sessionStorage);
  const refreshToken = sessionStorage.getItem('refresh_token');
  const currentPhone = useSelector<
  {auth: {phone: number,},}>((state) => state.auth.phone) as number;
  const [
    canInputVerificationCode,
    setCanInputVerificationCode,
  ] = useState(false);
  const [
    canSendVerificationCode,
    setCanSendVerificationCode,
  ] = useState(false);
  const [
    canSubmitPhoneNumber,
    setCanSubmitPhoneNumber,
  ] = useState(false);
  const [
    newPhoneNumber,
    setNewPhoneNumber,
  ] = useState('');
  const [
    submitPhoneNumberErrors,
    setSubmitPhoneNumberErrors,
  ] = useState<string[]>(Boolean(loginAs) || loginAs === 'true' ? [
    'You cannot change a user\'s phone number as an admin.',
  ] : []);
  const [
    submitPhoneNumberLoading,
    setSubmitPhoneNumberLoading,
  ] = useState(false);
  const [
    submitPhoneNumberSuccess,
    setSubmitPhoneNumberSuccess,
  ] = useState(false);
  const [
    submitVerificationErrors,
    setSubmitVerificationErrors,
  ] = useState<string[]>([]);
  const [
    submitVerificationLoading,
    setSubmitVerificationLoading,
  ] = useState(false);
  const [
    submitVerificationSuccess,
    setSubmitVerificationSuccess,
  ] = useState(false);
  const [
    verificationNumber,
    setVerificationNumber,
  ] = useState('');
  const [
    verificationTimer,
    setVerificationTimer,
  ] = useState(0);

  // Validate as we add phone number
  const addPhoneNumber = useCallback(
    (currentPhoneInput: string) => {
      // Whenever phone number is changed, should not be able to input verification number
      setCanInputVerificationCode(false);
      const {
        validationPassed,
        errorMsgs,
      } = validatePhoneNumber(`${currentPhone}`, currentPhoneInput);
      setSubmitPhoneNumberErrors(errorMsgs);

      if (validationPassed) {
        setNewPhoneNumber(currentPhoneInput);
        setCanSubmitPhoneNumber(true);
      } else {
        setCanSubmitPhoneNumber(false);
      }
    }, [
      currentPhone,
      setNewPhoneNumber,
      setSubmitPhoneNumberErrors,
    ]);

  // Validate whenever we add the verification number
  const addVerificationNumber = useCallback(
    (verificationCode: string) => {
      const {
        canSubmit,
        errorMsgs,
        validationPassed,
      } = validateVerificationCode(verificationCode);

      if (validationPassed) {
        setVerificationNumber(verificationCode);
      }

      setSubmitVerificationErrors(errorMsgs);
      setCanSendVerificationCode(canSubmit);
    }, [
      setVerificationNumber,
    ],
  );

  const submitAddNumberRequest = useCallback(async (selectedMethod: string) => {
    setCanInputVerificationCode(false);
    if (!canSubmitPhoneNumber) {
      return;
    }

    const {
      errorMsgs,
      validationPassed,
    } = validatePhoneNumber(`${currentPhone}`, newPhoneNumber);

    if (!validationPassed) {
      setSubmitPhoneNumberErrors(errorMsgs);
      setSubmitPhoneNumberSuccess(false);
      return;
    }

    setSubmitPhoneNumberLoading(true);

    const data =
    await authApi.changeVerificationMethodFromSettings({
      phone: newPhoneNumber,
      refreshToken,
      verificationChannel: selectedMethod as VerificationChannel,
    });

    setSubmitPhoneNumberLoading(false);
    setVerificationTimer(MAX_VERIFICATION_RESEND);
    if (data) {
      const {
        isVerificationRequired,
        verificationRequiredPayload,
      } = data.data;

      if (isVerificationRequired) {
        storeItemsInStorage(verificationRequiredPayload, sessionStorage);
        setSubmitPhoneNumberSuccess(true);
        setCanInputVerificationCode(true);
        setVerificationNumber('');
      } else {
        setSubmitPhoneNumberSuccess(false);
        setCanInputVerificationCode(false);
        setSubmitPhoneNumberErrors([
          'Could not change phone number.',
        ]);
        setVerificationNumber('');
      }
    }
  }, [
    canSubmitPhoneNumber,
    currentPhone,
    newPhoneNumber,
    refreshToken,
  ]);

  const submitChangeNumberRequest = useCallback(async () => {
    setCanInputVerificationCode(false);
    if (!canSubmitPhoneNumber) {
      return;
    }

    const {
      errorMsgs,
      validationPassed,
    } = validatePhoneNumber(`${currentPhone}`, newPhoneNumber);

    if (!validationPassed) {
      setSubmitPhoneNumberErrors(errorMsgs);
      setSubmitPhoneNumberSuccess(false);
      return;
    }

    const payload = {
      loginAs: loginAs || false,
      phoneNumber: newPhoneNumber,
      refreshToken,
    };

    setSubmitPhoneNumberLoading(true);

    const {
      data,
      error,
      success,
    } = await authApi.changePhoneNumber(payload);

    setSubmitPhoneNumberLoading(false);
    setVerificationTimer(MAX_VERIFICATION_RESEND);

    if (!success && error) {
      setSubmitPhoneNumberSuccess(false);
      setSubmitPhoneNumberErrors([
        error,
      ]);
      return;
    }

    const {
      isVerificationRequired,
      verificationRequiredPayload,
    } = data;

    if (isVerificationRequired) {
      storeItemsInStorage(verificationRequiredPayload, sessionStorage);
      setSubmitPhoneNumberSuccess(true);
      setCanInputVerificationCode(true);
      setVerificationNumber('');
    } else {
      setSubmitPhoneNumberSuccess(false);
      setCanInputVerificationCode(false);
      setSubmitPhoneNumberErrors([
        'Could not change phone number.',
      ]);
      setVerificationNumber('');
    }
  }, [
    canSubmitPhoneNumber,
    currentPhone,
    loginAs,
    newPhoneNumber,
    refreshToken,
  ]);

  const verifyVerificationNumber = useCallback(async () => {
    if (!canSendVerificationCode) {
      return { errorMessage:
        'Cannot send verification code.',
      success: false };
    }

    setSubmitVerificationLoading(true);

    try {
      const { data,
        success,
        errorMsg } = await authApi.verificationWithoutCallback({
        code: verificationNumber,
      });

      if (success) {
        setSubmitVerificationSuccess(true);
        dispatch(actionCreator(authActionTypes.SET_LOGIN_SUCCESS, data));

        try {
          await dispatch(userApi.me());
        } catch (error) {
          if (isAxiosError(error)) {
            navigate('/auth/login');
            Alert(error?.response?.data?.message);
            return { errorMessage:
              error?.response?.data?.message,
            success: false };
          }
        }

        return { errorMessage: '',
          success: true };
      } else {
        setSubmitVerificationErrors([
          errorMsg as string,
        ]);
      }

      return { errorMessage:
        'Verification failed.',
      success: false };
    } catch (error) {
      setSubmitVerificationSuccess(false);
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message || 'An error occurred.'
        : 'An unexpected error occurred.';
      return { errorMessage,
        success: false };
    } finally {
      setSubmitVerificationLoading(false);
    }
  }, [
    canSendVerificationCode,
    dispatch,
    navigate,
    verificationNumber,
  ]);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (verificationTimer > 0) {
        setVerificationTimer(verificationTimer - 1);
      }
    }, 1_000);
    return () => clearInterval(countdownInterval);
  }, [
    verificationTimer,
  ]);

  const values = useMemo(() => ({
    addPhoneNumber,
    addVerificationNumber,
    canInputVerificationCode,
    canSendVerificationCode,
    canSubmitPhoneNumber,
    newPhoneNumber,
    setSubmitVerificationErrors,
    submitAddNumberRequest,
    submitChangeNumberRequest,
    submitPhoneNumberErrors,
    submitPhoneNumberLoading,
    submitPhoneNumberSuccess,
    submitVerificationErrors,
    submitVerificationLoading,
    submitVerificationSuccess,
    verificationNumber,
    verificationTimer,
    verifyVerificationNumber,
  }), [
    addPhoneNumber,
    addVerificationNumber,
    canInputVerificationCode,
    canSendVerificationCode,
    canSubmitPhoneNumber,
    newPhoneNumber,
    submitChangeNumberRequest,
    submitAddNumberRequest,
    submitPhoneNumberErrors,
    submitPhoneNumberLoading,
    submitPhoneNumberSuccess,
    submitVerificationErrors,
    submitVerificationLoading,
    submitVerificationSuccess,
    verificationNumber,
    verificationTimer,
    setSubmitVerificationErrors,
    verifyVerificationNumber,
  ]);

  return <ChangePhoneNumberContext.Provider value={values}>
    {children}
  </ChangePhoneNumberContext.Provider>;
};
