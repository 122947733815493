/* eslint-disable max-len */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable canonical/filename-match-exported */
import {
  authActionTypes,
  filesActionTypes,
  firmActionTypes,
  firmsActionTypes,
  globalActionTypes,
} from '../store/actions';
import Api from '.';
import {actionCreator,
  Alert,
  processAuthorization,
  unauthorized} from './utils';
import {setDisplayType} from 'store/slices';
import {storeItemsInStorage} from 'types';

class UserApi extends Api {
  constructor () {
    super('/api/users');
  }

  me () {
    return async (dispatch) => {
      // dispatch(actionCreator(globalActionTypes.LOADING, true));
      // try {
      const {data} = await this.get('/me');
      dispatch(actionCreator(authActionTypes.SET_LOGIN_SUCCESS, data));
      dispatch(setDisplayType({display: data.displayType,
        id: data.displayType}));
      storeItemsInStorage({
        displayType: data.displayType,
        email: data.email,
        fullPhoneNumber: data.phone,
        loginAs: data.loginAs,
        verificationChannel: data.verificationChannel,
      }, sessionStorage);
    };
  }

  migration (body, navigate) {
    return (dispatch) => {
      dispatch(actionCreator(globalActionTypes.LOADING, true));
      this.post('/migrateUser', body)
        .then(({data}) => {
          processAuthorization(
            data,
            dispatch,
            this.me,
            navigate,
            '/auth/verification',
            '/firms',
          );
        })
        .catch((error) => {
          unauthorized(error?.response?.status);
          Alert(error?.response?.data?.message);
        })
        .then(() => dispatch(actionCreator(globalActionTypes.LOADING, false)));
    };
  }

  async editMail (body) {
    try {
      await this.post('/edit-email', body);
      storeItemsInStorage({email: body.email}, sessionStorage);
    } catch (error) {
      unauthorized(error?.response?.status);
      Alert(error?.response?.data?.message);
    }
  }

  confirmEmail (body, email) {
    return (dispatch) => {
      dispatch(actionCreator(globalActionTypes.LOADING, true));
      this.post('/confirm-email', body)
        .then(() => {
          dispatch(actionCreator(firmActionTypes.GET_FIRM_VERIFY, false));
          dispatch(actionCreator(firmActionTypes.GET_FIRM_NOTIFICATION, true));
          dispatch(actionCreator(authActionTypes.CHANGE_EMAIL, email));
        })
        .catch((error) => {
          unauthorized(error?.response?.status);
          Alert(error?.response?.data?.message);
        })
        .then(() => {
          dispatch(actionCreator(globalActionTypes.LOADING, false));
          setTimeout(() => dispatch(actionCreator(firmActionTypes.GET_FIRM_NOTIFICATION, false)), 3_000);
        });
    };
  }

  confirmPhone (body, phoneNumber) {
    return (dispatch) => {
      dispatch(actionCreator(globalActionTypes.LOADING, true));
      this.post('/confirm-phone', body)
        .then(() => {
          dispatch(actionCreator(firmActionTypes.GET_FIRM_VERIFY, false));
          dispatch(actionCreator(firmActionTypes.GET_FIRM_NOTIFICATION, true));
          dispatch(actionCreator(authActionTypes.CHANGE_PHONE_NUMBER, phoneNumber));
        })
        .catch((error) => {
          unauthorized(error?.response?.status);
          Alert(error?.response?.data?.message);
        })
        .then(() => {
          dispatch(actionCreator(globalActionTypes.LOADING, false));
          setTimeout(() => dispatch(actionCreator(firmActionTypes.GET_FIRM_NOTIFICATION, false)), 3_000);
        });
    };
  }

  getFirm (firmId) {
    return (dispatch) => {
      dispatch(actionCreator(filesActionTypes.CLEAR_FILES));
      dispatch(actionCreator(firmActionTypes.FIRM_LOADING, true));
      this.get('/firm/' + firmId)
        .then(({data}) => {
          dispatch(actionCreator(firmActionTypes.GET_FIRM_DETAILS, data));
          dispatch(actionCreator(firmActionTypes.FIRM_LOADING, false));
        })
        .catch((error) => {
          unauthorized(error?.response?.status);
          Alert(error?.response?.data?.message);
        });
    };
  }

  getFirms () {
    return async (dispatch) => {
      dispatch(actionCreator(globalActionTypes.LOADING, true));
      try {
        const {data} = await this.get('/firms');
        dispatch(actionCreator(firmsActionTypes.GET_FIRMS_SUCCESS, data));
      } catch (error) {
        unauthorized(error?.response?.status);
        Alert(error?.response?.data?.message);
      } finally {
        dispatch(actionCreator(globalActionTypes.LOADING, false));
      }
    };
  }

  privacyPolicy () {
    this.post('/privacy-policy')
      .catch((error) => {
        unauthorized(error?.response?.status);
        Alert(error?.response?.data?.message);
      });
  }

  support (body, setSupportSuccess, setSupportLoading, setSupportModal) {
    setSupportLoading(true);
    this.post('/support', body, {}, true)
      .then(() => setSupportSuccess(true))
      .catch((error) => {
        unauthorized(error?.response?.status);
        Alert(error?.response?.data?.message);
      })
      .then(() => {
        setSupportLoading(false);
        setTimeout(() => setSupportModal(false), 10_000);
      });
  }
}

const userApi = new UserApi();

export default userApi;
