import fileArchivePublicApi from 'api/fileArchivePublicApi';
import { LoginLogo,
  Spinner } from 'components';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { useCallback,
  useEffect,
  useState } from 'react';
import { useParams } from 'react-router-dom';

export const DownloadArchive = () => {
  const { jobId } = useParams();
  const [
    isLoading,
    setIsLoading,
  ] = useState(true);
  const [
    error,
    setError,
  ] = useState<string | null>(null);

  const fetchDownloadLink = useCallback(async () => {
    try {
      if (!jobId) {
        throw new Error('The link is expired');
      }

      const { url: downloadUrl } = await fileArchivePublicApi.getDownloadLink(jobId);
      window.location.href = downloadUrl;
    } catch {
      setError('The link is expired');
    } finally {
      setIsLoading(false);
    }
  }, [
    jobId,
  ]);

  useEffect(() => {
    fetchDownloadLink();
  }, [
    fetchDownloadLink,
  ]);

  const renderCard = (content: React.ReactNode) =>
    <div className='flex min-h-screen items-center justify-center bg-black-200'>
      <div className='main-white-card flex size-[22rem] flex-col items-center gap-3 rounded-medium p-10'>
        <LoginLogo />
        <div className='flex flex-col items-center gap-6'>
          {content}
        </div>
      </div>
    </div>;

  if (isLoading) {
    return <div className='flex min-h-screen items-center justify-center'><Spinner /></div>;
  }

  if (error) {
    return renderCard(
      <>
        <div className='flex flex-col items-center gap-0.5 pt-1'>
          <Svgicon
            className='size-10'
            color='#6F777B'
            id='link-broken'
          />
          <span className='text-title-2 font-medium text-black-900'>{error}</span>
        </div>
        <Button
          onClick={() => {
            window.location.href = 'https://www.orcainc.com/';
          }}
          size='bigger-medium'
          squared
          text='To home page'
          type='fms-main'
        />
      </>,
    );
  }

  return renderCard(
    <>
      <div className='flex flex-col items-center gap-0.5'>
        <Svgicon
          className='size-10'
          color='#6F777B'
          id='download-3f'
        />
        <span className='text-title-2 font-medium text-black-900'>
          Download is starting
        </span>
        <span className='text-center text-font-3 text-black-600'>
          If for some reason the download didn't begin, press the button below.
        </span>
      </div>
      <Button
        onClick={() => fetchDownloadLink()}
        size='bigger-medium'
        squared
        text='Download again'
        type='fms-main'
      />
    </>,
  );
};
