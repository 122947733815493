// eslint-disable-next-line canonical/filename-match-exported
import { notificationsService } from '../services';
import {
  authActionTypes,
  globalActionTypes,
  rootActionTypes,
} from '../store/actions';
import { Api } from './Api';
import { type SuccessResponse } from './common.response';
import userApi from './user';
import {
  actionCreator,
  Alert,
  type AsyncThunkAction,
  processAuthorization,
  unauthorized,
} from './utils';
import { type Dispatch } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { type NavigateFunction } from 'react-router-dom';
import { RECAPTCHA } from 'resources/constants';
import {
  type AuthorizeVerificationType,
  type PrivacyPolicyFromStorage,
  storeItemsInStorage,
  type VerificationChannel,
} from 'types';

const api = new Api('/api/auth');

type ResendVerificationRequest = {
  channel: VerificationChannel,
};
const resendVerification = (body: ResendVerificationRequest) => api.post<
ResendVerificationRequest, SuccessResponse>('/login/verification/resend', body, {});

type ForgotPasswordRequest = {
  email: string,
};
const forgotPassword = (body: ForgotPasswordRequest) => api.post<
ForgotPasswordRequest, SuccessResponse>('/password-reset', body, {});

type LoginRequestBody = {
  email: string,
  password: string,
};
const login = (
  payload: LoginRequestBody,
  navigate: NavigateFunction,
  verificationRoute: string,
  noVerificationRoute: string,
  afterLoginCallback?: () => void,
) => async (
  dispatch: AsyncThunkAction | Dispatch<{ payload: unknown, type: string, }>,
) => {
  const dispatchAsActionDispatch = dispatch as Dispatch<
  { payload: unknown, type: string, }>;

  const dispatchAsThunkDispatch = dispatch as AsyncThunkAction;

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));

  const dispatchAction = async () => {
    try {
      await dispatchAsThunkDispatch(userApi.me());
    } catch (error) {
      if (isAxiosError(error)) {
        navigate('/auth/login');
        Alert(error?.response?.data?.message);
        Alert('Unable to authorize identity. Please try again.');
      }
    }
  };

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));
  try {
    const { data } = await api.post<LoginRequestBody, AuthorizeVerificationType>('/login', payload, {});

    processAuthorization(
      data,
      dispatch,
      dispatchAction,
      navigate,
      verificationRoute,
      noVerificationRoute,
      afterLoginCallback,
    );
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 403) {
        navigate('/auth/migration');
        return;
      }

      if (error.response?.data.message === RECAPTCHA.ERRORS.SCORE_TOO_LOW) {
        throw new Error(RECAPTCHA.ERRORS.SCORE_TOO_LOW);
      }

      const message = error.response?.data?.message || error.message as string;

      notificationsService.error(message);
    }
  } finally {
    dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, false));
  }
};

type ChangePhoneNumberBody = {
  loginAs: boolean | string,
  phoneNumber: string,
  refreshToken: string | null,
};

type VerificationRequestBody = {
  code: string,
};
type VerificationRequestResponse = {
  access_token?: string,
  refresh_token?: string,
};
const verification = (
  payload: VerificationRequestBody,
  navigate: NavigateFunction,
  nextPage: string,
  afterVerificationCallback?: () => void,
) => async (
  dispatch: AsyncThunkAction | Dispatch<{ payload: unknown, type: string, }>,
) => {
  const dispatchAsActionDispatch = dispatch as Dispatch<
  { payload: unknown, type: string, }>;

  const dispatchAsThunkDispatch = dispatch as AsyncThunkAction;

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));

  let verificationRequestResponse: VerificationRequestResponse;

  try {
    const { data } = await api.post<
    VerificationRequestBody, VerificationRequestResponse>(
      '/login/verification',
      payload,
      {},
    );

    verificationRequestResponse = data;
    storeItemsInStorage({
      access_token: verificationRequestResponse.access_token,
      refresh_token: verificationRequestResponse.refresh_token,
    }, sessionStorage);
  } catch {
    dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, false));
    notificationsService.error('Code is incorrect.');
    return;
  }

  dispatchAsActionDispatch(actionCreator(authActionTypes.SET_LOGIN_SUCCESS
    , verificationRequestResponse));

  try {
    await dispatchAsThunkDispatch(userApi.me());
  } catch (error) {
    if (isAxiosError(error)) {
      navigate('/auth/login');
      Alert(error?.response?.data?.message);
      Alert('Unable to authorize identity. Please try to login again.');
    } else {
      notificationsService.error('Code is incorrect.');
    }

    dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, false));

    return;
  }

  if (afterVerificationCallback) {
    afterVerificationCallback();
  }

  navigate(nextPage);

  setTimeout(() => {
    dispatchAsActionDispatch(
      actionCreator(globalActionTypes.LOADING, false),
    );
  },
  2_000);
};

const validateDocumentToken = async () => {
  const { data } = await api.post<
  unknown, { access_token: string, }
  >('/validateDocumentToken', {}, {});

  return data.access_token;
};

const confirmForgotPassword = (
  payload: {
    password: string,
    token: string | null,
  },
  navigate: NavigateFunction,
) => async (dispatch: Dispatch<{ payload: unknown, type: string, }>) => {
  dispatch(actionCreator(globalActionTypes.LOADING, true));

  try {
    await api.post('/password-reset/confirm', payload, {});
    notificationsService.success('Password successfully changed');
    navigate('/auth/login');
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
    } else {
      Alert('Could not change password successfully');
    }
  } finally {
    dispatch(actionCreator(globalActionTypes.LOADING, false));
  }
};

const logout = (
  navigate: NavigateFunction,
) => async (dispatch: Dispatch<{ payload: unknown, type: string, }>) => {
  dispatch(actionCreator(globalActionTypes.LOADING, true));
  try {
    navigate('/auth/login', { replace: true });
    await api.get('/logout');
    localStorage.clear();
    sessionStorage.clear();
    dispatch(actionCreator(rootActionTypes.CLEAR_STORE, {}));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    if (isAxiosError(error)) {
      unauthorized(error?.response?.status || 404);
      Alert(error?.response?.data?.message);
    }
  } finally {
    dispatch(actionCreator(globalActionTypes.LOADING, false));
  }
};

type ResetVerificationMethodBody = {
  phone?: string | null,
  refreshToken?: string | null,
  verificationChannel: VerificationChannel,
};
const resetVerificationMethod = (
  payload: ResetVerificationMethodBody,
  navigate?: NavigateFunction,
) => async (
  dispatch: AsyncThunkAction | Dispatch<{ payload: unknown, type: string, }>,
) => {
  const dispatchAsActionDispatch = dispatch as Dispatch<
  { payload: unknown, type: string, }>;

  const dispatchAsThunkDispatch = dispatch as AsyncThunkAction;

  const dispatchAction = async () => {
    try {
      await dispatchAsThunkDispatch(userApi.me());
    } catch (error) {
      if (isAxiosError(error) && navigate) {
        navigate('/auth/login');
        Alert(error?.response?.data?.message);
        Alert('Unable to authorize identity. Please try again.');
      }
    }
  };

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));
  try {
    const { data } = await api.post<
    ResetVerificationMethodBody, AuthorizeVerificationType
    >('/reset-auth-method', payload, {});
    processAuthorization(
      data,
      dispatch,
      dispatchAction,
      navigate as NavigateFunction,
      '/auth/verification',
      '/firm/settings',
    );
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
    }
  } finally {
    dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, false));
  }
};

const changeVerificationMethodFromSettings = async (
  payload: ResetVerificationMethodBody,
) => {
  try {
    const resp = await api.post<
    ResetVerificationMethodBody, AuthorizeVerificationType
    >('/reset-auth-method', payload, {});

    return resp;
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
    }

    return null;
  }
};

type SignUpBody = {
  password: string | null,
  phone?: string | null,
  token: string | null,
  verificationChannel: VerificationChannel,
};
const signUp = (
  payload: SignUpBody,
  navigate: NavigateFunction,
) => async (
  dispatch: AsyncThunkAction | Dispatch<{ payload: unknown, type: string, }>,
) => {
  const dispatchAsActionDispatch = dispatch as Dispatch<
  { payload: unknown, type: string, }>;

  const dispatchAsThunkDispatch = dispatch as AsyncThunkAction;

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));

  const dispatchAction = async () => {
    try {
      await dispatchAsThunkDispatch(userApi.me());
    } catch (error) {
      if (isAxiosError(error)) {
        navigate('/auth/login');
        Alert(error?.response?.data?.message);
        Alert('Unable to authorize identity. Please try again.');
      }
    }
  };

  dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, true));

  try {
    const { data } = await api.post<
    SignUpBody, AuthorizeVerificationType
    >(`/signup/confirm/${payload.token}`, payload, {});

    storeItemsInStorage<PrivacyPolicyFromStorage>(
      { privacyPolicy: 'true' },
      sessionStorage,
    );

    processAuthorization(
      data,
      dispatch,
      dispatchAction,
      navigate,
      '/auth/verification',
      '/firms',
    );
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
    }
  } finally {
    dispatchAsActionDispatch(actionCreator(globalActionTypes.LOADING, false));
  }
};

const impersonateByAdmin = async (
  payload: { token: string, },
) => {
  try {
    const result: { data: { token: string, }, } = await api.post('/impersonateByAdmin', payload, {});

    storeItemsInStorage<{ access_token: string, }>(
      { access_token: result.data.token },
      sessionStorage,
    );
  } catch (error) {
    if (isAxiosError(error)) {
      Alert(error?.response?.data?.message);
      unauthorized(error?.response?.status as number);
    } else {
      Alert('Could not login as admin');
    }
  }
};

const verificationWithoutCallback = async (
  payload: VerificationRequestBody,
) => {
  let dataToReturn: {
    data: VerificationRequestResponse,
    errorMsg?: string,
    success: boolean,
  };

  try {
    const { data } = await api.post<
    VerificationRequestBody, VerificationRequestResponse>(
      '/login/verification',
      payload,
      {},
    );
    dataToReturn = {
      data,
      success: true,
    };
  } catch (error) {
    let errorMsg = '';
    if (isAxiosError(error)) {
      errorMsg = error?.response?.data?.message;
    }

    dataToReturn = {
      data: {
        access_token: '',
        refresh_token: '',
      },
      errorMsg: errorMsg || 'Code is incorrect.',
      success: false,
    };
  }

  return dataToReturn;
};

const changePhoneNumber = async (
  payload: ChangePhoneNumberBody,
) => {
  let dataToReturn: {
    data: AuthorizeVerificationType,
    error?: string,
    success: boolean,
  };
  try {
    const { data } = await api.post<
    ChangePhoneNumberBody, AuthorizeVerificationType
    >('/change-phone', payload, {});
    dataToReturn = {
      data,
      success: true,
    };
  } catch (error) {
    let errorMsg = '';
    if (isAxiosError(error)) {
      errorMsg = error?.response?.data?.message;
    }

    dataToReturn = {
      data: {
        isVerificationRequired: false,
        verificationNotRequiredPayload: {
          access_token: '',
          isMFAmethodFinalised: true,
          refresh_token: '',
        },
      },
      error: errorMsg || 'Could not change phone number',
      success: false,
    };
  }

  return dataToReturn;
};

type ChangePasswordBody = {
  confirmPassword: string,
  newPassword: string,
  password: string,
};

const changePassword = async (
  body: ChangePasswordBody,
) => {
  let changePasswordSuccess = true;
  const currentPasswordErrors: string[] = [];
  try {
    await api.post('/change-password', body, {});
  } catch (error) {
    changePasswordSuccess = false;
    let reqStatus: number;
    if (isAxiosError(error)) {
      currentPasswordErrors.push(error?.response?.data?.message);
      reqStatus = error?.response?.status || 400;
    } else {
      reqStatus = 400;
    }

    setTimeout(() => {
      unauthorized(reqStatus);
    }, 3_000);
  }

  return {
    changePasswordSuccess,
    submissionErrors: {
      currentPassword: currentPasswordErrors,
      newPassword: [],
      retypeNewPassword: [],
    },
  };
};

// copying directly from previous auth
const verifyToken = (
  token: string | null,
  navigate: NavigateFunction,
) => api.post(`/verify-token/${token}`, {}, {})
  // eslint-disable-next-line promise/prefer-await-to-then
  .catch((error) => {
    if (isAxiosError(error)) {
      notificationsService.error(error?.response?.data?.message);
      if (error?.response?.status === 400) {
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          navigate('/auth/login');
        }, 4_000);
      }
    }
  });

const authApi = {
  changePassword,
  changePhoneNumber,
  changeVerificationMethodFromSettings,
  confirmForgotPassword,
  forgotPassword,
  impersonateByAdmin,
  login,
  logout,
  resendVerification,
  resetVerificationMethod,
  signUp,
  validateDocumentToken,
  verification,
  verificationWithoutCallback,
  verifyToken,
};

export default authApi;
