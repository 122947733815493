import userApi from '../../../api/user';
import {getItemsFromStorage,
  type PageRedirectInfoFromStorage,
  removeItemsFromStorage,
  storeItemsInStorage} from '../../../types';
import style from './Login.module.scss';
import authApi from 'api/auth';
import { LoginButton,
  LoginHeading,
  LoginInput,
  LoginPasswordInput,
  WelcomeMessage} from 'components';
import { useDocumentTitle } from 'hooks';
import { useCallback,
  useEffect,
  useRef,
  useState } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { useDispatch,
  useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate } from 'react-router-dom';
import { RECAPTCHA } from 'resources/constants';
import { analyticsService,
  notificationsService } from 'services';
import { type AppDispatch } from 'store';

type RecaptchaPayload = {
  email: string,
  password: string,
  recaptchaV2Token?: string,
  recaptchaV3Token: string,
};

export const Login = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParameters = new URLSearchParams(search);
  const {
    loginAs,
    token,
  } = Object.fromEntries(queryParameters.entries());

  useEffect(() => {
    sessionStorage.removeItem('onLoginPage');
    sessionStorage.removeItem('canResetPassword');
  }, []);

  const isLoading = useSelector<{
    global: {loading: boolean, },
  }>((state) => state.global.loading) as boolean;

  const [
    email,
    setEmail,
  ] = useState('');
  const [
    password,
    setPassword,
  ] = useState('');
  const [
    showCaptchaV2,
    setShowCaptchaV2,
  ] = useState(false);
  const captchaRef = useRef<Recaptcha>(null);
  const [
    isExecutingRecaptcha,
    setIsExecutingRecaptcha,
  ] = useState(false);

  useDocumentTitle('Investor Portal Login');

  const {
    loginMessage,
    nextPage,
  } = getItemsFromStorage<PageRedirectInfoFromStorage>([
    'loginMessage',
    'nextPage',
  ], sessionStorage);

  const targetNextPage = nextPage || '/firms';
  const verificationPage = '/auth/verification';
  // eslint-disable-next-line require-unicode-regexp, unicorn/no-unsafe-regex, unicorn/better-regex, regexp/no-unused-capturing-group, regexp/no-super-linear-backtracking, regexp/prefer-w, max-len, regexp/prefer-d
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;

  useEffect(() => {
    // Load reCAPTCHA script
    const script = document.createElement('script');
    script.src =
      `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA.V3_SITE_KEY}`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const executeRecaptcha = useCallback(async () => {
    setIsExecutingRecaptcha(true);
    try {
      await window.grecaptcha.ready(() => {});
      return await window.grecaptcha.execute(RECAPTCHA.V3_SITE_KEY, { action: 'login' });
    } finally {
      setIsExecutingRecaptcha(false);
    }
  }, []);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sessionStorage.setItem('onLoginPage', 'true');

    if (!emailRegex.test(email.toLowerCase())) {
      notificationsService.error('Email is invalid');
      return;
    }

    try {
      const recaptchaV3Token = await executeRecaptcha();

      const payload: RecaptchaPayload = {
        email,
        password,
        recaptchaV3Token,
      };

      if (showCaptchaV2) {
        const recaptchaV2Token = captchaRef.current?.getValue();
        if (!recaptchaV2Token) {
          notificationsService.error('Please complete the captcha');
          return;
        }

        payload.recaptchaV2Token = recaptchaV2Token;
      }

      await dispatch(
        authApi.login(
          payload,
          navigate,
          verificationPage,
          targetNextPage,
        ),
      )
        .then(() => removeItemsFromStorage([
          'emailForResetPassword',
        ], sessionStorage))
        .catch((error) => {
          if (error.message === RECAPTCHA.ERRORS.SCORE_TOO_LOW) {
            setShowCaptchaV2(true);
          }
        });
      analyticsService.sendLoginEvent();

      removeItemsFromStorage<PageRedirectInfoFromStorage>([
        'loginMessage',
        'nextPage',
      ], sessionStorage);
    } catch {
      notificationsService.error('Failed to verify reCAPTCHA. Please try again.');
    }
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
    storeItemsInStorage({
      emailForResetPassword: value,
    }, sessionStorage);
  };

  useEffect(() => {
    const checkLoginAs = async () => {
      if (loginAs && token) {
        storeItemsInStorage<{ access_token: string, }>(
          { access_token: token },
          sessionStorage,
        );
        await authApi.impersonateByAdmin({token});
        dispatch(userApi.me());
        navigate('/firms');
      }
    };

    checkLoginAs();
  }, [
    dispatch,
    loginAs,
    navigate,
    token,
  ]);

  return <div className={style.loginWrapper}>
    <div className='flex flex-col gap-1.5'>
      {
        loginMessage ?
          <LoginHeading htmlContent={loginMessage} /> :
          <WelcomeMessage />
      }
    </div>
    <form onSubmit={onSubmit}>
      <div className={style.inputsWrapper}>
        <LoginInput autoCompleteField='email' name='login-username-input' onChange={handleChangeEmail} placeholder='Email' type='email' value={email} />
        <LoginPasswordInput autoCompleteField='password' name='login-password-input' onChange={setPassword} placeholder='Password' value={password} />
        {showCaptchaV2 &&
          <Recaptcha
            className='mb-1'
            ref={captchaRef}
            sitekey={RECAPTCHA.V2_SITE_KEY}
          />
        }
      </div>
      <LoginButton
        isLoading={isLoading || isExecutingRecaptcha}
        name='login-button'
        text='Sign In'
        type='submit'
      />
    </form>
  </div>;
};
