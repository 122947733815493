import Api from '.';

class FileArchivePublicApi extends Api {
  public constructor () {
    super('/api/v1/public/archives');
  }

  public async getDownloadLink (id: string) {
    const { data } = await this.get(`/${id}`);
    return data as { url: string, };
  }
}

const fileArchivePublicApi = new FileArchivePublicApi();
export default fileArchivePublicApi;
