import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CookieManagementButton = () => {
  useEffect(() => {
    // Ensure that the Termly script is fully loaded and initialized
    const termlyScriptUrl = 'https://app.termly.io/resource-blocker/513835d2-7c8f-4ee7-9719-3f08f3b11f8a?autoBlock=off';
    const doesTermlyExist = document.querySelector(`script[src="${termlyScriptUrl}"]`);
    if (!doesTermlyExist) {
      const script = document.createElement('script');
      script.src = termlyScriptUrl;
      script.async = true;
      document.head.appendChild(script);
    }
  });

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className='termly-display-preferences text-black-600 hover:text-label-g-300 hover:underline'
      href='#'
    >
      Cookie Management
    </a>
  );
};

export const PrivacyAndTerms = () => {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const isAuthPage = location.pathname.includes('login') || location.pathname.includes('signup') || location.pathname.includes('forgot-password');
  const containerClasses = classNames(
    'flex',
    'w-full',
    'flex-col',
    'items-center',
    'gap-0.5',
    {
      'justify-end bg-black-100': !isAuthPage,
      'z-10 justify-between': isAuthPage,
    },
    'p-1',
    'text-font-2',
    'text-black-600',
    'dt:flex-row',
  );

  return (
    <div className={containerClasses}>
      {isAuthPage && <div className='flex flex-col items-center gap-0.5 text-black-600 dt:flex-row dt:justify-start'>
        <span className='flex gap-0.25'>
          ©{currentYear}. All Rights Reserved. Powered by Orca
        </span>
      </div>}
      <div className='flex flex-row items-center gap-[0.35rem]'>
        <a className='text-black-600 hover:text-label-g-300 hover:underline' href={'https://www.orcainc.com/terms-of-use'} rel='noreferrer' target='_blank'>Terms of use</a>
        •
        <a className='text-black-600 hover:text-label-g-300 hover:underline' href={'https://www.orcainc.com/privacy-policy'} rel='noreferrer' target='_blank'>Privacy policy</a>
        {isAuthPage && <>
          <span>•</span>
          <CookieManagementButton />
        </>}
      </div>
    </div>
  );
};

