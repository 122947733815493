import checkTokenExpiry from './checkTokenExpiry';
import clearStore from './clearStore';
import logger from './logger';
import { type Dispatch,
  type Middleware,
  type UnknownAction } from '@reduxjs/toolkit';
import {isProduction} from 'services/environment';

const middlewares: Array<Middleware<{}, unknown, Dispatch<UnknownAction>>> = [
  ...isProduction() ? [] : [
    logger,
  ],
  clearStore,
  checkTokenExpiry,
].filter(Boolean);

export default middlewares;
